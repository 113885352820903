.invprofileimg {
    /* position: absolute; */
    top: 5px;
    left: 10px;
    border: 4px solid #fff;
    border-radius: 34px;
}

.mainBG {
    background: #fbfbfb;
    padding: 20px;
}

.SaleProfile {
    border-radius: 8px;
    padding: 10px 25px 10px 0px;
    color: var(--whiteBG);
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.mainhd {
    background: #fbfbfb;
    padding: 20px;
}

.Invboxicon {
    background: #dadada;
    color: #6E5199;
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 30px;
}

.dropdown-toggle {
    cursor: pointer;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-content-stats {
    position: absolute;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    z-index: 1;
    border-radius: 0px 0px 12px 12px;
    width: 155px;
    -webkit-transition: opacity 2s, visibility 2s;
    -moz-transition: opacity 2s, visibility 2s;
    -ms-transition: opacity 2s, visibility 2s;
    -o-transition: opacity 2s, visibility 2s;
    transition: opacity 2s, visibility 2s;
    left: -16px;
    top: 45px;
    display: block;
    visibility: visible;
    opacity: 1;
}

.dropdown-content-stats a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    color: #04091E;
}

.dropdown-content-stats a:hover {
    background-color: #ddd;
    border-radius: 0px;
    transition: 0.4s ease 0s;
}

/* .dropdown:hover .dropdown-content-stats {
    display: block;
    visibility: visible;
    opacity: 1;
} */

@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}


body {
  font-family: "Urbanist", sans-serif !important;
  font-optical-sizing: auto;
  background-color: #F5F5F5;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-content {
  width: 130% !important;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-section {
  margin-bottom: 20px;
}

.modal-item {
  margin-bottom: 10px;
}


.form-Input:disabled {
  background-color: rgb(0 0 0 / 5%) !important;
  opacity: 1 !important;
  color: #a7a7a7 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-Input {
  padding: 7px !important;
  border-radius: 5px;
  border: 1px solid #AbA3A3;
  background-color: var(--whiteBG) !important;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.form-label {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
}

.end {
  justify-content: end;
  display: flex;
}


.maon-BG {
  border-radius: 8px;
  background: var(--whiteBG);
  box-shadow: var(--shadow);
  padding: 10px;
}

.main-header {
  border-radius: 36px;
  background: var(--whiteBG);
  box-shadow: var(--shadow);
  padding: 10px;
}

.css-13cymwt-control {
  padding: 3px !important;
  border-radius: 0px !important;
  border: 1px solid #AbA3A3 !important;
}

.css-t3ipsp-control {
  width: 204px !important;
  padding: 3px !important;
  border: none !important;
}

td .css-b62m3t-container .css-13cymwt-control {
  width: 204px !important;
  margin: 0;
  border-radius: 0px !important;
  border: var(--border) !important;
}


td .css-b62m3t-container .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

td .css-lmndiq5-menu {
  width: 220px !important;
}

td .css-b62m3t-container .css-lmndiq5-menu {
  width: 220px !important;
}

.ag-header-row-column {
  background-color: #C4C8CB !important;
  color: #000000 !important;
  font-weight: 500 !important;
}

.css-10wo9uf-option:active {
  background: #6E5199 !important;
  color: #fff !important;
}

.css-10nhe30-control {
  border-color: #6E5199 !important;
  box-shadow: none !important;
}

.css-10wo9uf-option:hover {
  background: #6E5199 !important;
  color: #fff !important;
}

.css-tr4s17-option {
  background-color: #6E5199 !important;
}

.css-tr4s17-option:hover {
  background-color: rgba(0, 123, 255, 0.1) !important;
  color: #ffffff !important;
}

/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .w-25 {
    width: 75% !important;
  }

  .w-75 {
    width: 100% !important;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 1024px) {

  .w-25 {
    width: 85% !important;
  }

  .w-75 {
    width: 80% !important;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1024px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .w-75 {
    width: 84% !important;
  }
}

@media (width: 1024px) {
  .rows {
    flex-direction: column;
  }

  .col6 {
    width: 100%;
  }

  .w-25 {
    width: 45% !important;
  }

  .col4 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {}

@media only screen and (min-width:1280px) and (max-width:1440px) {
  .form-label {
    font-size: 13px;
    min-width: 93px;
  }

  .colmd-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .D-none {
    display: none;
  }
}

@media only screen and (min-width:1441px) and (max-width :1600px) {
  .form-label {
    font-size: 12px;
  }
}

@media only screen and (min-width:1600px) and (max-width :1800px) {
  .form-label {
    font-size: 12px;
  }
}

@media only screen and (width: 1024px) {
  .D-none {
    display: none;
  }

  .colmd-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .form-label {
    font-size: 13px;
    min-width: 93px;
  }
}

@media only screen and (min-width:1441px) {}

@media only screen and (width: 1440px) {}

@media only screen and (width: 1024px) {}


:root {
  /* --background: #5B93FF; */
  /* --background: #D79031; */
  --border: 1px solid #5C5E64;
  /* --color: #5B93FF; */
  --color: #D79031;
  --whiteBG: #fff;
  --shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
  --black: #231F20;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--whiteBG);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8888886c;
}

.mainInvHead {
  font-size: large;
  font-weight: 600;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6E5199;
}

.slide-container {
  border-radius: 8px 8px 0 0;
  border: var(--border);
  background: var(--whiteBG);
}

.mainbtn {
  background-color: #6E5199;
  color: #F5F5F5;
  font-size: 16px;
  border: none;
  padding: 5px 25px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.mainbtn:hover {
  background-color: #826ca1;
}

.mainbtnRed {
  background-color: #DC3545;
  color: #F5F5F5;
  font-size: 16px;
  border: none;
  padding: 5px 25px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.mainbtnRed:hover {
  background-color: #e7616d;
}

.reversbtn {
  background-color: #c098e5;
  color: #F5F5F5;
  font-size: 16px;
  border: none;
  padding: 5px 25px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.reversbtn:hover {
  background-color: #c098e5;
}

.gridbtn {
  justify-content: center;
  border: var(--border);
  border-radius: 15px;
  background: #313a46;
  display: flex;
  color: var(--whiteBG);
  align-items: center;
  text-align: center;
  font-size: 14px;
  height: 30px;
  font-weight: 400;
  width: 130px;
}

.gridDisab {
  background-color: #a6afb6;
  border-radius: 30px;
  height: 30px;
  font-size: 14px;
  width: 130px;
  color: var(--whiteBG);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mainbtnGry {
  background-color: #313a46;
  color: #F5F5F5;
  font-size: 16px;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.mainbtnSecondary {
  background-color: #6c757d;
  color: #F5F5F5;
  font-size: 16px;
  border: none;
  padding: 5px 25px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}


.pend {
  background-color: #f44336 !important;
  color: var(--whiteBG) !important;
  width: 100% !important;
  height: 30px !important;
  font-size: 14px !important;
  border-radius: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.pend:hover {
  background-color: var(--blackBG) !important;
  color: var(--whiteBG) !important;
}

.aproved {
  background-color: #6E5199 !important;
  color: var(--whiteBG) !important;
  width: 100% !important;
  height: 30px !important;
  font-size: 14px !important;
  border-radius: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.aproved:hover {
  background-color: var(--blackBG) !important;
  color: var(--whiteBG) !important;
}

.reject {
  background-color: #DC3545 !important;
  color: var(--whiteBG) !important;
  width: 100% !important;
  height: 30px !important;
  font-size: 14px !important;
  border-radius: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.reject:hover {
  background-color: var(--blackBG) !important;
  color: var(--whiteBG) !important;
}

.hold {
  background-color: var(--blackBG) !important;
  color: var(--whiteBG) !important;
  width: 100% !important;
  height: 30px !important;
  font-size: 14px !important;
  border-radius: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.hold:hover {
  background-color: var(--blackBG) !important;
  color: var(--whiteBG) !important;
}

.sidebar-text {
  color: #747681;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}

.sideActive {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  padding: 8px 30px;
  border-radius: 36px;
  background-color: #6E5199 !important;
  color: var(--whiteBG) !important;
}

.start {
  align-items: start !important;
  display: flex !important;
  justify-content: start !important;
  text-align: start !important;
}

.form-label {
  color: #5c5e64;
  font-size: 14px;
  font-weight: 400 !important;
  letter-spacing: -.28px;
}

.ag-theme-quartz {
  --ag-header-height: 40px !important;
  --ag-row-border-style: solid !important;
  --ag-row-border-width: 0.5px !important;
  --ag-row-border-color: #b3adbb !important;
  --ag-cell-horizontal-border: solid #b3adbb !important;
}

[class*=ag-theme-] {
  font-family: "Urbanist", sans-serif !important;
}

.ag-header-cell-text {
  font-weight: 600 !important;
}



.ag-root-wrapper {
  border-radius: 0px !important;
  border: none !important;
}

.ag-header,
.ag-advanced-filter-header {
  height: 35px !important;
}

.ag-row {
  height: 35px !important;
}

.form-check-input:checked {
  background-color: #6E5199 !important;
  border-color: #6E5199 !important;
}

.gridBg {
  background-color: var(--whiteBG);
  border: 1px solid #c7c7c7;
  border-radius: 16px 16px 0 0;
  padding: 20px;
}

.gridshad {
  border: 1px solid #c7c7c7;
  box-shadow: var(--shadow);
}

.form-switch .form-check-input {
  height: 18px !important;
  width: 32px !important;
}

.ag-row:hover {
  background-color: #D3CAE0 !important;
}

.ag-row-hover {
  background-color: #D3CAE0 !important;
}

.ag-row-hover:hover {
  background-color: #D3CAE0 !important;
}

.custom-row-border .ag-row {
  border-bottom: 1px solid #ddd;
}

.ag-row-animation .ag-row {
  transition: transform .4s, top .4s;
}

.brderbox {
  border-radius: 8px;
  border: var(--border);
  background: var(--whiteBG);
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.brderbox2 {
  border-radius: 0px 0px 10px 10px;
  border: var(--border);
  background: var(--whiteBG);
}

.cost-center-section {
  width: 100%;
  overflow: visible;
}

.slide-header {
  background-color: var(--whiteBG);
  border: 1px solid #c7c7c7;
  border-radius: 8px 8px 0 0;
  padding: 10px;
  cursor: pointer;
  box-shadow: var(--shadow);
  transition: background-color 0.5s ease;
}

.slide-header:hover {
  background-color: #e2e6ea;
}

.start {
  display: flex !important;
  justify-content: start !important;
  align-items: start !important;
  text-align: start !important;
}

.slide-content {
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: 0;
}

.slide-content.open {
  max-height: 1000px;
}

.border-box2 {
  background-color: var(--whiteBG);
  border: 1px solid #d4d4d4;
  /* border-radius: 0 0 8px 8px; */
}

.border-box {
  background-color: var(--whiteBG);
  border: var(--border);
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0.6716145873069763, 0.6374742984771729, 0.6374742984771729, 0.38999998569488525));
}

.form-label {
  font-weight: 500;
  min-width: 120px;
}

@media (max-width: 768px) {
  .form-group .col-md-4 {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .form-label {
    min-width: 100px;
  }
}

.dynoTable tr th {
  padding: 10px;
  background-color: #6E5199;
  color: var(--whiteBG);
}



.InvProfile {
  border-radius: 8px;
  /* background: #6E5199; */
  padding: 10px 25px 10px 0px;
  color: var(--whiteBG);
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.pagclr {
  color: #6E5199;
}
@media (min-width:768px) {
	body {
		padding-top: 0
	}
}



.body {
	background-color: #F9F9F9;
}

@media (min-width: 768px) {
	.sidebar.fixed-left {
		width: 160px;
		flex-flow: column nowrap;
		align-items: flex-start;
	}
}

/* Sidebar Layout */
.sidebar-layout {
	display: flex;
	height: 100vh;
	overflow: hidden;
}

.sidebar {
	width: auto;
	transition: width 0.3s ease;
	/* position: sticky; */
	/* top: 0; */
	/* bottom: 0; */
	/* z-index: 1; */
}

.pro-item-content {
	font-weight: 300 !important;
	font-size: 15px;
	line-height: 177%;
}

.nav-text {
	color: var(--neutral-07, #2C2F24);
	text-align: center;
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}

.fixed-lefty {
	width: auto !important;
	position: sticky
}

.middle {
	align-items: center;
}

.main-content {
	flex-grow: 1;
	overflow-y: auto;
	z-index: 0;
}

.mainbdy {
	padding-top: 60px;
}

/* Header */
.mainBG {
	position: fixed;
	width: 100%;
	z-index: 2;
}

.invHead {
	background: var(--whiteBG);
	position: fixed;
	z-index: 1;
	width: -webkit-fill-available;
	padding: 7px;
	box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.25);
}

.ovrflo {
	overflow: auto;
}

.ovrflo:hover {
	overflow: auto;
}

.ovrflo::-webkit-scrollbar {
	width: 3px;
}

.ovrflo::-webkit-scrollbar-thumb {
	background-color: #888;
}

.ovrflo::-webkit-scrollbar-thumb:hover {
	background-color: #6E5199;
}

.ovrflo::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

.logo {
	padding: 35px;
}

.navbar {
	background: var(--whiteBG);
	box-shadow: 0px 10px 40px 0px rgba(204, 208, 219, 0.50);
}

.side-flex {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-content: center;
	align-items: flex-start;
}

.nav-link.h6 {
	color: #3C4469;
	text-decoration: none;
	font-size: 10px;
	font-weight: 500;
}

.nav-item.a {
	border-radius: 8px;
}

/* .nav-link:hover {
	background: #0d6efd21;
	border-radius: 8px;
	cursor: pointer;
	font-weight: 500;
} */

.submenu {
	font-size: 13.5px;
	font-style: normal;
	font-weight: 400;
	color: #484848;
}

.subactive {
	background-color: #D3CAE0;
	color: #6E5199;
	border-radius: 5px !important
}

.submenu:hover {
	color: #6E5199 !important;
}


@media (min-width:768px) {
	.navbar.fixed-lefty {
		bottom: 0;
		width: 190px;
		flex-flow: column nowrap;
		align-items: flex-start;
		height: 100%;
	}

	.navbar.fixed-lefty .navbar-collapse {
		flex-grow: 0;
		flex-direction: column;
		width: 100%
	}

	.navbar.fixed-lefty .navbar-collapse .navbar-nav {
		flex-direction: column;
		width: 100%
	}

	.navbar.fixed-lefty .navbar-collapse .navbar-nav .nav-item {
		width: 100%
	}

	.navbar.fixed-lefty .navbar-collapse .navbar-nav .nav-item .dropdown-menu {
		top: 0
	}
}

@media (min-width:768px) {
	.navbar.fixed-lefty {
		right: auto
	}

	.navbar.fixed-lefty .navbar-nav .nav-item .dropdown-toggle:after {
		border-top: .3em solid transparent;
		border-left: .3em solid;
		border-bottom: .3em solid transparent;
		border-right: none;
		vertical-align: baseline
	}

	.navbar.fixed-lefty .navbar-nav .nav-item .dropdown-menu {
		left: 100%
	}
}


/* Media Queriess  Start*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.logo {
		padding: 0px !important;
		padding-left: 15px !important;
	}

	.sidebar-layout {
		display: list-item;
	}

	.sidebar {
		position: sticky !important;
	}

	.fixed-left {
		width: 100% !important;
	}

	.sidebar-layout {
		height: 100%;
	}

	.invHead {
		position: sticky;
	}

	.mainBG {
		position: sticky;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}


/* ProSidebar */

.pro-sidebar>.pro-sidebar-inner {
	background: var(--whiteBG) !important;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
	background-color: var(--whiteBG) !important;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
	background-color: var(--whiteBG) !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
	background-color: var(--whiteBG) !important;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
	font-size: 16px !important;
	font-weight: 600 !important;
	background-color: #D3CAE0 !important;
	color: #6E5199 !important;
	padding: 0 !important;
	text-align: left !important;
	border-radius: 5px !important
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
	padding: 20px !important;
}

.pro-sidebar .pro-menu a {
	text-align: left !important;
	padding: 0 !important;
	font-size: 16px !important;
	background-color: #EFF4FF !important;
	border-radius: 5px !important;
	color: #484848 !important;
	font-weight: 600 !important;
	letter-spacing: 0.5px;
}

.pro-sidebar .pro-menu a.active {
	color: #6E5199 !important;
}

.pro-sidebar .pro-menu {
	padding-top: 1px !important
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
	color: #6E5199 !important;
	background-color: #D3CAE0 !important;
	border-radius: 5px !important
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
	padding: 0px !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
	padding-top: 5px !important;
}

.pro-sidebar {
	color: #484848 !important;
	width: 280px !important;
	min-width: 280px !important;
	height: 100vh !important;
	transition: width, left, right, 0.3s !important;
	box-shadow: 1px 0px 11px -9px rgba(0, 0, 0, 0.25) !important;
}

.pro-sidebar.collapsed {
	width: 80px !important;
	min-width: 80px !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
	color: #484848 !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
	font-size: 14px !important;
	margin-top: 5px !important;
}

.sidebar-toggle-btn {
	position: fixed;
	top: 20px;
	transition: left 0.3s ease;
}

:root {
	/* --background: #D79031 !important; */
	--border: 1px solid #5C5E64;
	--color: #7E57C2;
	--whiteBG: #ffffff;
	--shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.25);
	--black: #231F20;
}